import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import Layout from '@components/layout'

const CctvFlushingPage = () => {
  return (
    <Layout pageTitle="CCTV &amp; Flushing">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../../media/cctv_hero.jpg"
                alt=""
                aspectRatio={3.75 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>Services</h2>
                <h1>CCTV &amp; Flushing</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
      <Container fluid="xxl" className="section text-center">
          <Row>
            <Col>
              <h3>
                Sewers are essential to hygienic conditions. Wessuc is dedicated to inspecting and cleaning sewer lines in a responsible manner.
              </h3>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col md={{ order: 'first' }} xs={{ order: 'last' }}>
              <StaticImage 
                src="../../../media/inspection_cctv.jpg"
                alt="a small metal robot with a camera on the front and mud on its tires" />
            </Col>
            <Col>
              <h3>CCTV</h3>
              <p>
                CCTV (Closed Circuit Television) is a <strong>non-intrusive method</strong> of inspecting pipes and sewers, meaning you don't have to dig anything up to do it!
              </p>
              <p>
                Wessuc uses these <strong>remote controlled units</strong> to search for any signs of damage or blockage and we can do it on any pipe larger than 100mm!
              </p>
              <p>
                We can zoom in and provide a closeup look at the areas in question. On completion, you have a thorough <strong>visual and written report</strong> of the situation to help you plan your next steps.
              </p>
              <Button href="/contact" variant="primary" className="shadow">I'm Interested!</Button>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col>
              <h3>FLUSHING</h3>
              <p>
                Wessuc’s advanced flushing trucks provided <strong>high pressure cleaning</strong> and vacuuming of wastewater collection systems, storm water drainage systems, lift stations, pump stations, manholes, catch basins, <strong>and more.</strong>
              </p>
              <p>
                With over <strong>hundreds of feet of flushing hose</strong>, we can apply a range of water pressure into 100mm pipes and larger to <strong>flush out debris blockage</strong>. Then we air vacuum that debris out an into our 12 yard debris tank.
              </p>
              <p>
                In addition to confined space training, our flushing crews are trained for a better understanding of pipe conditions.
              </p>
                <Button href="/contact" variant="primary" className="shadow">I'm Interested!</Button>
            </Col>
            <Col>
              <StaticImage 
                src="../../../media/inspection_flushing.jpg"
                alt="orange hose from a hose reel on the front of a large truck going into a manhole" />
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}

export default CctvFlushingPage